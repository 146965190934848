import React, { createContext, useCallback, ReactNode, useContext, useMemo, useEffect } from 'react';
import { usePersistedState } from '@commonstock/common/src/utils/usePersistedState';
import { themeDark } from './themes/dark';
import { themeLight } from './themes/light';
import { css, cx } from '@linaria/core';
import { ChatListWidth, Colors, MobileBreakpoint, SmallMobileBreakpoint, SinglePaneBreakpoint, Theme } from './constants';
import { isLoggedIn } from '@commonstock/common/src/auth';
import useMediaQuery from '@commonstock/common/src/utils/useMediaQuery';
import useIsomorphicLayoutEffect from 'src/utils/useIsomorphicLayoutEffect';
type ThemeValues = {
  toggleTheme: () => Theme;
  theme: Theme;
  isMobile?: boolean;
  isSmallMobile?: boolean;
  isSinglePane?: boolean;
  isDarkTheme: boolean;
};
const initialValue = {
  toggleTheme: () => Theme.Dark,
  theme: Theme.Dark,
  isDarkTheme: true
};
const ThemeContext = createContext<ThemeValues>(initialValue);
ThemeContext.displayName = 'ThemeContext';
type ThemeProps = {
  children: ReactNode;
  theme?: Theme;
  disableAnimations?: boolean;
};
export const ThemeProvider = ({
  children,
  theme: _theme,
  disableAnimations
}: ThemeProps) => {
  const initialTheme = _theme || null;
  const [persistedTheme, setPersistedTheme] = usePersistedState<Theme>('cs:theme', initialTheme || Theme.Dark);
  const theme = useMemo(() => {
    return initialTheme || persistedTheme;
  }, [initialTheme, persistedTheme]);
  const toggleTheme = useCallback(() => {
    let newTheme = theme === Theme.Dark ? Theme.Light : Theme.Dark;
    setPersistedTheme(newTheme);
    return newTheme;
  }, [setPersistedTheme, theme]);
  useEffect(() => {
    const body = document.querySelector('body');
    body?.classList.remove(themeDark, themeLight);
    body?.classList.add(theme === Theme.Dark ? themeDark : themeLight);
  }, [theme]);
  let mobileQuery = `calc(${MobileBreakpoint} - ${!isLoggedIn() ? ChatListWidth : '0rem'})`;
  const isMobile = useMediaQuery(`screen and (max-width: ${mobileQuery})`);
  const isSmallMobile = useMediaQuery(`screen and (max-width: ${SmallMobileBreakpoint})`);
  let singlePaneQuery = `calc(${SinglePaneBreakpoint} - ${!isLoggedIn() ? ChatListWidth : '0rem'})`;
  const isSinglePane = useMediaQuery(`screen and (max-width: ${singlePaneQuery})`);
  const themeContextValues: ThemeValues = useMemo(() => ({
    theme,
    toggleTheme,
    isMobile,
    isSmallMobile,
    isSinglePane,
    isDarkTheme: theme === Theme.Dark
  }), [isMobile, isSmallMobile, isSinglePane, theme, toggleTheme]); // Not using these classes on theme-root, as it doesnt add the classes on first render

  useIsomorphicLayoutEffect(() => {
    const body = document.body;
    body?.classList.remove('theme-mobile', 'theme-single-pane', 'theme-small-mobile');
    isMobile && body?.classList.add('theme-mobile');
    isSinglePane && body?.classList.add('theme-single-pane');
    isSmallMobile && body?.classList.add('theme-small-mobile');
    isSmallMobile && body?.classList.add('theme-small-mobile');
    disableAnimations && body?.classList.add(disabledAnimationsClass);
  }, [disableAnimations, isMobile, isSinglePane, isSmallMobile]);
  return <ThemeContext.Provider value={themeContextValues}>
      <div id={'theme-root'} className={themeClass}>
        {children}
      </div>
    </ThemeContext.Provider>;
};
export const useTheme = () => {
  return useContext(ThemeContext);
};
export const LightMode = ({
  children,
  className
}: {
  children?: ReactNode;
  className?: string;
}) => {
  return <div className={cx(themeLight, className)}>{children}</div>;
};
const themeClass = "t1k0d2ui";
const disabledAnimationsClass = "d10665nn";

require("../../.linaria-cache/packages/oxcart/src/theme/ThemeContext.linaria.module.css");