import {
  ParamsGeneric,
  CacheType,
  MethodOptions,
  Resource,
  Loading,
  Fail,
  RequestStage,
  ResourceFlag,
  HttpMethods
} from '../constants'
import { HookOptions, createUseFetch } from './createUseFetch'
import { useMemo, useReducer, useRef, useCallback } from 'react'
import { inferCacheKey } from '../inferCacheKey'
import { useClient } from './context'

export type PagerOptions<Payload, Params> = {
  getCursor: (page: number, resource?: Resource<Payload, Params>) => ParamsGeneric
  isTerminal: (resource: Resource<Payload, Params>, page: number) => boolean
}

export type Pager<Payload, Params> = {
  next: () => Promise<Resource<Payload, Params>> | undefined
  reset: () => Promise<Resource<Payload, Params>>
  terminal: boolean
}

export type PagedReturnTuple<Payload, Params> = [
  Payload[] | undefined,
  Fail | undefined,
  Loading<Payload, Params>,
  Pager<Payload, Params>,
  Array<Resource<Payload, Params> | void>,
  boolean
] & {
  pages: Payload[] | undefined
  fail: Fail | undefined
  pending: Loading<Payload, Params>
  pager: Pager<Payload, Params>
  requests: Array<Resource<Payload, Params> | void>
  firstPageLoaded: boolean
}

// type as any to avoid void property access issues
function applyCursor(params: any, cursorParams: any, methodOptions?: MethodOptions<any>): ParamsGeneric {
  let json: object | null = null
  if (methodOptions?.method === HttpMethods.Post) json = { ...cursorParams?.json, ...params?.json }
  const newCursor = {
    ...params,
    query: { ...cursorParams?.query, ...params?.query }
  }
  if (json) newCursor.json = json
  return newCursor
}
// @TODO omit nonsensical options from methodOptions like independent and cacheType
function createUsePagedFetch<Payload, Params extends ParamsGeneric = void | null>(
  methodOptions: MethodOptions<Params>,
  pagerOptions: PagerOptions<Payload, Params>
) {
  let useFetch = createUseFetch<Payload, Params>(methodOptions)
  return (params: Params, hookOptions: HookOptions = {}): PagedReturnTuple<Payload, Params> => {
    const [, forceRender] = useReducer(s => s + 1, 0) // taken from react-redux https://github.com/reduxjs/react-redux/blob/master/src/hooks/useSelector.js#L46

    // to avoid referential inequality issues, stringify params
    // @TODO can we avoid similar redundant work done in useFetch?
    const paramsString = useMemo(() => {
      // @ts-ignore not sure why it doesnt know params is an object
      return JSON.stringify(params)
    }, [params])

    // setup refs
    let lastParamsStringRef = useRef(paramsString)
    let activeRequest = useRef<Promise<Resource<Payload, Params>> | void>()
    let terminal = useRef(false)
    let page = useRef(0)

    // reset everything if params change
    if (lastParamsStringRef.current !== paramsString) {
      lastParamsStringRef.current = paramsString
      page.current = 0
      terminal.current = false
    }

    let client = useClient()
    let { cache } = client

    let baseCacheKey = useMemo(() => {
      return inferCacheKey(
        methodOptions.api,
        methodOptions.key,
        // createUseFetch sets HttpMethod when not provided, this makes the request runs twice as the first key
        // has a HttpMethod and it creates a different key on rerender due to new HttpMethod
        methodOptions.method || HttpMethods.Get,
        CacheType.Memory,
        false,
        paramsString + String(0)
      )
    }, [paramsString])

    let baseAppliedParams = useMemo(() => {
      let cursor = pagerOptions.getCursor(0)
      return applyCursor(params, cursor, methodOptions)
    }, [paramsString]) // eslint-disable-line

    // @TODO get rid of the Params coercion
    let [, , , refetch, firstPage] = useFetch(baseAppliedParams as Params, {
      ...hookOptions,
      cacheKey: baseCacheKey
    })

    let pages: Resource<Payload, Params>[] = []
    if (firstPage) {
      pages.push(firstPage)
      if (page.current === 0) terminal.current = pagerOptions.isTerminal(firstPage, 0)
    }
    for (let i = 1; i <= page.current; i++) {
      let cacheKey = inferCacheKey(
        methodOptions.api,
        methodOptions.key,
        methodOptions.method,
        CacheType.Memory,
        false,
        paramsString + String(i)
      )
      let resource = cache.get(cacheKey) as Resource<Payload, Params>
      pages[i] = resource
    }

    // refresh any pages that have been flagged as NeedsRefetch
    const handlePageRefresh = useCallback(
      async (i = 1) => {
        if (i >= pages.length) return
        // @NOTE: Dont need to update first page, since useFetch already does it altomatically
        const prevPage = pages[i - 1]
        const page = pages[i]
        const pageIndex = i - 2
        // @NOTE: Refetch by key sets all keys to NeedsRefetch
        if (
          page.flag === ResourceFlag.NeedsRefetch &&
          prevPage.flag === ResourceFlag.Stable &&
          prevPage.stage === RequestStage.Success
        ) {
          let cursor = pagerOptions.getCursor(pageIndex, prevPage) as Params
          const updatedPage = await refetch(applyCursor(params, cursor, methodOptions) as Params, page.cacheKey)
          pages[i] = updatedPage
          // @NOTE: Sequentially update, since the list items could have changed since last update
          forceRender()
          handlePageRefresh(i + 1)
        }
      },
      [pages, params, refetch]
    )

    if (pages.length > 1 && firstPage?.stage === RequestStage.Success) handlePageRefresh()

    let lastPage = pages[page.current] || undefined

    let pager = useMemo(() => {
      let next = () => {
        if (activeRequest.current) return activeRequest.current
        if (pager.terminal) {
          console.log('## pager is terminal. Noop on next page')
          return
        }
        if (lastPage.stage === RequestStage.InFlight) {
          console.log('## last page still in flight. Noop')
          return
        }
        if (lastPage.stage === RequestStage.Fail) {
          console.log('## last page failed. Noop')
          return
        }
        if (lastPage.stage === RequestStage.Success) page.current++
        else {
          // @TODO confirm if retry is the right behavior
          console.log('## pager.next called, the last page was not successful, retrying')
        }

        let cacheKey = inferCacheKey(
          methodOptions.api,
          methodOptions.key,
          methodOptions.method,
          CacheType.Memory,
          false,
          paramsString + String(page.current)
        )
        let cursor = pagerOptions.getCursor(page.current, lastPage)

        // @TODO get rid of the type coercion
        let request = refetch(applyCursor(params, cursor, methodOptions) as Params, cacheKey)
        activeRequest.current = request
        request.then(resource => {
          terminal.current = pagerOptions.isTerminal(resource, page.current)
        })
        request.catch(reason => {
          // Set as terminal when a page failed, to avoid fetching new ones
          terminal.current = true
          console.error('# Failed to get new paginated content', reason)
        })
        request.finally(() => {
          activeRequest.current = undefined
          forceRender()
        })
        return request
      }
      let reset = () => {
        page.current = 0
        terminal.current = false
        let request = refetch()
        activeRequest.current = request
        request.finally(() => {
          // @NOTE we dont force render here because the base useFetch will handle that
          activeRequest.current = undefined
        })
        forceRender()
        return request
      }

      return {
        next,
        reset,
        terminal: terminal.current
      }
    }, [paramsString, refetch, lastPage, terminal.current]) // eslint-disable-line

    let mergedPayload = pages.filter(page => !!page.success).map(page => page?.success?.payload) as Payload[]

    const firstPageLoaded = pages.length > 0 && pages[0]?.stage !== RequestStage.InFlight

    const tuple = [
      mergedPayload,
      lastPage?.fail,
      activeRequest.current || false,
      pager,
      pages,
      firstPageLoaded
    ] as PagedReturnTuple<Payload, Params>

    // Support object destructuring, by adding the specific values.
    tuple.pages = mergedPayload
    tuple.fail = lastPage?.fail
    tuple.pending = activeRequest.current || false
    tuple.pager = pager
    tuple.requests = pages
    tuple.firstPageLoaded = firstPageLoaded

    return tuple
  }
}

export { createUsePagedFetch }
