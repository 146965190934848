import { Resource } from '@commonstock/client/src/constants'
import * as Sentry from '@sentry/react'
import config from '../config'

function setUpSentrySDK() {
  // @NOTE: restricting Sentry to not local environments
  if (location.hostname !== 'localhost')
    Sentry.init({
      dsn: config.sentryDsn,
      environment: config.CS_ENV,
      release: process.env.GIT_SHA
    })
}

function captureException(
  err: Error | unknown,
  extra?: Record<string, any>,
  fingerprint?: Array<string>
): Promise<string | void> {
  return new Promise(resolve => {
    console.error('captureException:', err, process.env.NODE_ENV)
    if (process.env.NODE_ENV !== 'production') {
      resolve(undefined)
    } else {
      Sentry.withScope(scope => {
        if (extra) scope.setExtras(extra)
        if (fingerprint) scope.setFingerprint(fingerprint)
        const eventId = Sentry.captureException(err)
        console.log('report', eventId)
        resolve(eventId)
      })
    }
  })
}

async function unexpectedError(err: any) {
  // dont log if an api error because we already log that in the client
  // @TODO we may make api errors no longer throw in which case we can remove this check
  let identifier = err.fail
    ? `${err.methodOptions?.path} - ${err.fail?.status}`
    : (await captureException(err)) || 'n/a'
  alert(`An unexpected error occured (${identifier})`)
}

async function reportFailedResource(resource: Resource<any, any>) {
  let err = new Error('resource fail')
  captureException(err, { resource }, [resource.methodOptions.key, '' + resource.fail?.status || 'no-http-status'])
}

if (typeof window !== 'undefined') {
  setUpSentrySDK()
}

export { captureException, unexpectedError, reportFailedResource }
