import { UserRole } from '@commonstock/common/src/types'

export enum Authenticated {
  Yes = 'yes',
  No = 'no',
  Pending = 'pending'
}

export enum SocialProviders {
  Apple = 'apple',
  Facebook = 'facebook',
  Google = 'google-oauth2'
}

export { AuthTokensKey } from '@commonstock/common/src/auth'
export const Auth0StateKey = 'cs:auth0-state'
export const AccountConfirmedKey = 'cs:user-confirmed'

export const InitSessionTokenKey = 'cs:init-session-token'
export const InitRefreshTokenKey = 'cs:init-refresh-token'

export type JWTValues = {
  email: string
  exp: number
  id: number
  uuid: string
  roles: UserRole[]
}
