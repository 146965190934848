const requestIdleCallback =
  (typeof window !== 'undefined' && window.requestIdleCallback) ||
  function(cb: any) {
    let start = Date.now()
    return setTimeout(function() {
      cb({
        didTimeout: false,
        timeRemaining: function() {
          return Math.max(0, 50 - (Date.now() - start))
        }
      })
    }, 1)
  }

export default requestIdleCallback
