import { createUseFetchAction, createUseFetch } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods } from '@commonstock/client/src/constants'
import { ChartData, Periods } from '../types'
import { HydratedUserProfile } from './profile'

export type AuthInfo = {
  user_uuid: string
  sendbird_auth_token: string
  expires_at: number
}
export let useGetAuthInfoAction = createUseFetchAction<AuthInfo>({
  key: 'auth-info',
  path: '/chat/auth'
})

export type Conversation = {
  name: string
  channel_url: string
}
type CreateConversationPayload = {
  json: {
    user_uuids: string[]
  }
}
export const useCreateConversation = createUseFetchAction<Conversation, CreateConversationPayload>({
  key: 'create-conversation',
  method: HttpMethods.Post,
  path: '/chat/conversations'
})

export enum ChannelTypes {
  Direct = 'direct_message',
  Private = 'private_channel',
  Public = 'public_channel'
}
export type ChannelSettings = {
  leaderboard_enabled: boolean
  trade_alerts_enabled: boolean
}
export type Channel = {
  name: string
  type: ChannelTypes
  is_public: boolean
  channel_url: string
}
export type CreateChannelPayload = {
  json: {
    name: string
    // @TODO uuid will this prop change?
    user_uuids: string[]
    description?: string
    cover_url?: string
    settings: ChannelSettings
  }
}
export const useCreateChannel = createUseFetchAction<Channel, CreateChannelPayload>({
  key: 'create-channel',
  method: HttpMethods.Post,
  path: '/chat/channels'
})

export type UpdateChannelPayload = {
  json: {
    channel_url: string
    name?: string
    description?: string
    cover_url?: string
    user_uuids?: string[]
    settings?: ChannelSettings
  }
}
export const useUpdateChannel = createUseFetchAction<Channel, UpdateChannelPayload>({
  key: 'update-channel',
  method: HttpMethods.Put,
  path: params => `/chat/channels/${params.json.channel_url}`
})

type ChannelDeleteParams = {
  meta: {
    url: string
  }
}
export const useDeleteChannel = createUseFetchAction<void, ChannelDeleteParams>({
  key: 'delete-channel',
  method: HttpMethods.Delete,
  path: ({ meta }) => `/chat/channels/${meta.url}`
})

type RemoveMemberParams = {
  json: {
    url: string
    user_uuids: string[]
  }
}
export const useRemoveMember = createUseFetchAction<void, RemoveMemberParams>({
  key: 'remove-member',
  method: HttpMethods.Put,
  path: ({ json }) => `/chat/channels/${json.url}/remove`
})

type InviteMemberParams = {
  json: {
    url: string
    user_uuids: string[]
  }
}
export const useInviteMember = createUseFetchAction<void, InviteMemberParams>({
  key: 'invite-member',
  method: HttpMethods.Put,
  path: ({ json }) => `/chat/channels/${json.url}/invite`
})

type InviteToken = {
  token: string
  channel_url: string
  creator_id: number
  created_at: string
  created_at_timestamp: number
  max_uses: number
  uses: number
  share_link: {
    link: string
    image_url: string
  }
}

type InviteTokenParams = {
  json: {
    channel_url: string
  }
}

export const useInviteToken = createUseFetch<InviteToken, InviteTokenParams>({
  key: 'invite-token',
  method: HttpMethods.Post,
  path: `/chat/invites`
})

export const useInviteTokenAction = createUseFetchAction<InviteToken, InviteTokenParams>({
  key: 'invite-token-action',
  method: HttpMethods.Post,
  path: `/chat/invites`
})

export type ChannelSettingsParams = {
  include_in_leaderboard: boolean
  share_trade_alerts: boolean
}

type RedeemInviteToken = {
  name: string
  custom_type: string
  is_public: boolean
  channel_url: string
  data: string
}

type RedeemInviteTokenParams = {
  json: {
    token: string
    settings: ChannelSettingsParams
  }
}

export const useRedeemInviteToken = createUseFetchAction<RedeemInviteToken, RedeemInviteTokenParams>({
  key: 'redeem-token',
  method: HttpMethods.Put,
  path: `/chat/invites/redeem`
})

type UsersSettings = {
  channel_url: string
  include_in_leaderboard: boolean
  share_trade_alerts: boolean
  updated_at: string
  user_uuid: string
}

type UsersSettingsParams = {
  meta: {
    channel_url: string
  }
}

export const useGetUserSettings = createUseFetch<UsersSettings, UsersSettingsParams>({
  key: 'user-settings',
  path: ({ meta: { channel_url } }) => `/chat/channels/${channel_url}/user-settings`
})

type UsersSettingsUpdateParams = {
  meta: {
    channel_url: string
  }
  json: ChannelSettingsParams
}

export const useUpdateUserSettingsAction = createUseFetchAction<UsersSettings, UsersSettingsUpdateParams>({
  key: 'update-user-settings',
  path: ({ meta: { channel_url } }) => `/chat/channels/${channel_url}/user-settings`,

  method: HttpMethods.Put
})

export type GroupLeaderboardChart = {
  point_range: number
  portfolio_cash: number
  portfolio_value: number
  valid_period: boolean
} & ChartData

type GroupLeaderboard = {
  users: { user: HydratedUserProfile; chart: GroupLeaderboardChart }[]
}

type GroupLeaderboardParams = {
  meta: {
    channel_url: string
  }
  query: {
    period: Periods
    limit?: number
  }
}

export const useGetGroupLeaderboard = createUseFetch<GroupLeaderboard, GroupLeaderboardParams>({
  key: 'group-leaderboard',
  path: ({ meta: { channel_url } }) => `/chat/channels/${channel_url}/leaderboard`
})

export type GroupDetails = {
  channel: {
    name: string
    cover_url: string
    custom_type: string
    is_public: boolean
    channel_url: string
    joined_member_count: number
    members: null
    data: string
  }
  inviting_user: HydratedUserProfile
  invite: {
    token: string
    channel_url: string
    creator_uuid: string
    created_at: string
    created_at_timestamp: number
    uses: number
  }
  is_member: boolean
}

type GroupDetailsParams = {
  meta: {
    inviteToken: string
  }
}

export const useGetGroupDetailsFromToken = createUseFetch<GroupDetails, GroupDetailsParams>({
  key: 'group-details',
  path: ({ meta: { inviteToken } }) => `/chat/invites/${inviteToken}`
})

export const useGetGroupDetailsFromTokenAction = createUseFetchAction<GroupDetails, GroupDetailsParams>({
  key: 'group-details-action',
  path: ({ meta: { inviteToken } }) => `/chat/invites/${inviteToken}`
})
