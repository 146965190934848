import mixpanel from 'mixpanel-browser' // eslint-disable-line
import { ExtendedProfile } from '@commonstock/common/src/api/profile'
import config from '../../config'
import { Theme } from 'src/theme/constants'

export const PLATFORM = 'Web'

// dont track anything during SSR
if (typeof window !== 'undefined') mixpanel.init(config.mixpanelToken)

/* ===== KEY =====
 * .track = Event
 * .register = Super Properties
 * .people.set = User Properties
 */

export type EventObject = {
  event: string
  [key: string]: string | undefined
}

export function alias(uuid: string) {
  mixpanel.alias(uuid)
}

export function track(event: string, data?: object) {
  if (config.logTracking) console.log('## analytics', { event, data })
  mixpanel.track(event, data)
}

export function setAnalyticsLoggedOut(logged_out: boolean) {
  if (config.logTracking) console.log('## analytics', 'set logged out')
  mixpanel.register({ logged_out, platform: PLATFORM })
}

type UsersChannelCountObject = {
  direct_message_channel_count: number
  private_channel_count: number
}

export function setUsersChannelCount(data: UsersChannelCountObject) {
  mixpanel.people.set({
    direct_message_channel_count: data.direct_message_channel_count,
    private_channel_count: data.private_channel_count
  })
}

export function updateAnalyticsUser(user: ExtendedProfile) {
  if (config.logTracking) console.log('## analytics', 'update user', user)

  mixpanel.people.set({
    $avatar: user.picture,
    $name: user.name,
    $email: user.email,
    username: user.username,
    follower_assets: user.follower_assets,
    user_id: user.user_id,
    user_uuid: user.user_uuid,
    joined_at_timestamp: user.joined_at_timestamp,
    is_verified: user.verified,
    bio_set: Boolean(user.bio),
    follower_count: user.followers,
    following_count: user.following,
    has_linked_broker: user.aggregate_portfolio_value > 0,
    has_linked_twitter: Boolean(user.twitter_username),
    is_hidden: user.hidden,
    joined_at: user.joined_at,
    muted_user_count: user.muted_users.length,
    is_private: user.private,
    tags: user.tags,
    website_set: Boolean(user.website)
  })
  // @NOTE upon login its possible for some events to fire before these properties are set.
  // Generally unlikely though as most of the app requires the user is loaded.
  mixpanel.register({
    user_follower_count: user.followers,
    user_following_count: user.following,
    user_follower_assets: user.follower_assets,
    user_has_twitter_linked: Boolean(user.external_providers.find(x => x.canonical === 'twitter')),
    user_has_broker_linked: user.aggregate_portfolio_value > 0,
    platform: PLATFORM,
    logged_out: false
  })
  track('Session:Start')
}

type CSSuperProperties = {
  theme: Theme
}
export function setSuperProperties(properties: CSSuperProperties) {
  mixpanel.register(properties)
}

export function identifyAnalytics(uuid: string) {
  if (config.logTracking) console.log('## analytics', 'identify', uuid)
  mixpanel.identify(uuid)
}

export function logoutAnalytics() {
  mixpanel.reset()
  setAnalyticsLoggedOut(true)
}
