import React, { createContext, useCallback } from 'react'
import { useRef } from 'react'
import { useContext } from 'react'
import { ReactNode } from 'react'
import { AnalyticsNodeValue } from './constants'

type Props = {
  children?: ReactNode
} & AnalyticsNodeValue

export type CollectAnalytics = () => AnalyticsNodeValue

export const AnalyticsNodeContext = createContext<CollectAnalytics>(() => ({}))

export function AnalyticsNode({ children, ...dataProps }: Props) {
  let dataRef = useRef(dataProps)
  dataRef.current = dataProps
  const collectParentAnalytics = useContext(AnalyticsNodeContext)
  const collectAnalytics = useCallback(() => {
    let parentData = collectParentAnalytics()
    return { ...parentData, ...dataRef.current }
  }, [collectParentAnalytics])
  return <AnalyticsNodeContext.Provider value={collectAnalytics}>{children}</AnalyticsNodeContext.Provider>
}

export const useCollectAnalytics = () => useContext(AnalyticsNodeContext)
