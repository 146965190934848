import { CacheType, HttpMethods } from './constants'

function inferCacheKey(
  api = '',
  key: string,
  method: HttpMethods | '' = '',
  cacheType: CacheType,
  independent: boolean,
  signature: string
) {
  // cacheType:api:key:method:signature + :random if independent
  return `${cacheType}:${api}:${key}:${method}:${signature}:${independent ? Math.random() : ''}`
}

export { inferCacheKey }
