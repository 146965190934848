import { useGetMyProfile } from '../api/profile'
import { HookOptions } from '@commonstock/client/src/react/createUseFetch'
import { ReturnTuple } from '@commonstock/client/src/constants'
import { isLoggedIn } from '@commonstock/common/src/auth'

export type UserParams = { meta: { user_uuid: string }; headers?: any }
export type UserParamsPaged = { meta: { user_uuid: string }; query?: { limit: number; last_uuid?: string } }

function applyMe<Payload>(
  useFetchHook: (params: UserParams, hookOptions?: HookOptions) => ReturnTuple<Payload, UserParams>
) {
  // we keep the nothing argument to maintain argument parity with useFetchHook
  return (_nothing?: null, { paused }: HookOptions = {}): ReturnTuple<Payload, UserParams> => {
    let [user] = useGetMyProfile(null, { paused: !isLoggedIn() })
    return useFetchHook({ meta: { user_uuid: user?.user_uuid ?? 'n/a' } }, { paused: !user || paused })
  }
}

export default applyMe
