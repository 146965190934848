import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'
import { HttpMethods, Resource } from '@commonstock/client/src/constants'
import { HydratedUserProfile } from './profile'
import { createUsePagedFetch } from '@commonstock/client/src/react/createUsePagedFetch'
import { FeedResourceType } from './feed'

export enum NotificationStatus {
  Sent = 'sent',
  New = 'new',
  Read = 'read'
}

type BaseNotification = {
  category: string
  data: any
  uuid: string
  user_uuid: string
  sender: HydratedUserProfile | null
  subcategory: string
  title: string | null
  subtitle: string | null
  destination: string
  body: string
  created_at: string
  created_at_timestamp: number
  status: NotificationStatus
}

type BrokerNotification = BaseNotification & {
  category: 'broker-notification'
  data: {
    brokerage_id: number
    brokerage_canonical: string
  }
}

type LikeNotification = BaseNotification & {
  category: 'like-notifications'
  data: {
    aggregate_portfolio_value: string
    item_type: FeedResourceType
    item_uuid: string
  }
}

type CommentNotification = BaseNotification & {
  category: 'comment-notifications'
  data: {
    comment_uuid: string
    root_type: string
    root_user_uuid: string
    root_uuid: string
  }
}

type TradeNotification = BaseNotification & {
  category: 'trade-notifications'
  data: {
    asset_symbol: string
    order_type: string
    short_name: string
  }
}

type ProfileNotification = BaseNotification & {
  category: 'profile-notifications'
  data: {}
}

type ContentNotification = BaseNotification & {
  category: 'content-notifications'
  data: {
    ext_ref: string
    push_notification_copy: string
    title: string
    uuid: string
  }
}

export type Notification =
  | BrokerNotification
  | LikeNotification
  | CommentNotification
  | ProfileNotification
  | TradeNotification
  | ContentNotification

export type PagedNotifications = {
  notifications: Notification[]
  last_uuid: string
  total_count: number
}

export const useReadNotifications = createUseFetchAction({
  key: 'read-notifications',
  path: '/notifications/read',

  method: HttpMethods.Post
})

export const useClearNotifications = createUseFetchAction({
  key: 'clear-notifications',
  path: '/notifications/clear',

  method: HttpMethods.Post
})

let pagerOptions = {
  getCursor(page: number, resource?: Resource<PagedNotifications, void | null>) {
    return {
      query: {
        last_uuid: resource?.success?.payload?.last_uuid,
        limit: 20
      }
    }
  },
  isTerminal(resource: Resource<PagedNotifications, void | null>, page: number) {
    let total = (resource && resource.success?.payload?.total_count) || 0
    return (page + 1) * 20 > total
  }
}
export const useGetPagedNotifications = createUsePagedFetch<PagedNotifications>(
  {
    key: 'paged-notifications',
    path: '/notifications/',

    // Since websocket call refetchByKey it get precedence over this time
    cacheTime: 60 * 1000
  },
  pagerOptions
)
