import { queryStringToObj } from './utils'

const isBrokerAuthDeepLink = () => location.href.match(/^.+\/broker-auth\?.+$/) && window.opener

const brokerAuthDeepLink = () => {
  let detail = queryStringToObj(location.search)
  let event = new CustomEvent('broker-auth-return', { detail })
  opener?.dispatchEvent(event)
  close()
}

export const followDeepLinks = () => {
  if (isBrokerAuthDeepLink()) brokerAuthDeepLink()
}
