import { createUseFetchAction } from '@commonstock/client/src/react/createUseFetch'

import { HttpMethods, JsonObject } from '@commonstock/client/src/constants'
import { Apis } from '../types/apis'

type LoginPayload = {
  access_token: string
}
type LoginParams = {
  json: {
    grant_type: string
    username: string
    password: string
    scope: string
    client_id: string
  }
}
let usePostLoginAction = createUseFetchAction<LoginPayload, LoginParams>({
  key: 'login',
  method: HttpMethods.Post,
  path: '/oauth/token',
  api: Apis.Auth0
})

let usePostSignupAction = createUseFetchAction<any, any>({
  key: 'signup',
  method: HttpMethods.Post,
  path: '/dbconnections/signup',
  api: Apis.Auth0
})

export type StartSessionPayload = {
  address: string
  confirmed: boolean
  jwt: {
    refresh_token: string
    access_token: string
  }
}
type StartSessionParams = {
  headers: JsonObject
}
let usePostStartSessionAction = createUseFetchAction<StartSessionPayload, StartSessionParams>({
  key: 'start-session',
  path: '/identity/v1/auth0/start-session',
  method: HttpMethods.Post,
  api: Apis.Auth
})

type EmailStatusPayload = {
  address: string
  confirmed: boolean
}
type EmailStatusParams = {
  headers: {
    Authorization: string
  }
}
let useGetEmailStatusAction = createUseFetchAction<EmailStatusPayload, EmailStatusParams>({
  key: 'email-status',
  path: '/identity/v1/auth0/email-status',
  method: HttpMethods.Get,
  api: Apis.Auth
})

type ForgotPasswordParams = {
  json: {
    email: string
  }
}
let useForgotPasswordAction = createUseFetchAction<void, ForgotPasswordParams>({
  key: 'forgot-password',
  path: '/identity/v1/auth0/password-recovery',
  method: HttpMethods.Post,
  api: Apis.Auth
})

type ResendVerifyParams = {
  json: {
    refresh_token: string
  }
}
let useResendVerify = createUseFetchAction<void, ResendVerifyParams>({
  key: 'resend-verify',
  path: '/identity/v1/auth0/resend-verification',
  method: HttpMethods.Post,
  api: Apis.Auth
})

export {
  usePostLoginAction,
  usePostSignupAction,
  usePostStartSessionAction,
  useGetEmailStatusAction,
  useForgotPasswordAction,
  useResendVerify
}
