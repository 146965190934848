// @TODO remove once new browser adoption is sufficiently high (safari 12 is the problem)
if (!Promise.allSettled) {
  Promise.allSettled = promises =>
    Promise.all(
      promises.map(promise =>
        promise
          .then(value => ({
            status: 'fulfilled',
            value
          }))
          .catch(reason => ({
            status: 'rejected',
            reason
          }))
      )
    )
}
