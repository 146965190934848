import sleep from './sleep'
import { captureException } from '@commonstock/oxcart/src/dev/sentry'

export interface Options {
  /**
   * The delay in ms before a lock expires.
   *
   * This delay exists to ensure that locks held by tabs which have since been
   * closed or are frozen (eg. for performance reasons) do not prevent other
   * tabs from acquiring the lock.
   *
   * Defaults to DEFAULT_EXPIRY
   */
  expiry?: number

  /**
   * The name of the object store in the database to use.
   *
   * If an existing database is passed to the constructor, that database must
   * have an object store of this name.
   *
   * Defaults to 'mutexes'.
   */
  objectStoreName?: string

  /**
   * The amount of time to wait in ms between attempts to lock if the lock is
   * contended.
   *
   * Note that `lock()` does not spin at all if the lock is not currently held.
   *
   * Defaults to 50ms.
   */
  spinDelay?: number
}

/**
 * A mutex for coordinating cross-tab activities.
 */
export class Mutex {
  private _db: Promise<IDBDatabase>
  private _objectStoreName = 'mutexes'
  private _name: string
  private _id = Math.round(Math.random() * 10000).toString()
  private _expiry: number
  private _spinDelay: number

  /**
   * Initialize the mutex.
   *
   * @param name - Name of the mutex.
   * @param db - Existing database to use. If null, an IndexedDB database named
   *   'idb-mutex' is created. If an existing database is provided it must have
   *   an object store name matching `options.objectStoreName`.
   * @param options
   */
  constructor(name: string, db?: Promise<IDBDatabase> | null, options: Options = {}) {
    // Generate a good-enough random identifier for this instance.
    this._db = db || this._initDb(this._objectStoreName)
    this._name = name
    this._expiry = options.expiry ? options.expiry : 10000
    this._spinDelay = options.spinDelay ? options.spinDelay : 50
  }

  /**
   * Acquire the lock.
   *
   * If no other instance currently holds the lock, the previous lock has expired
   * or the current instance already holds the lock, then this resolves
   * immediately.
   *
   * Otherwise `lock()` waits until the current lock owner releases the lock or
   * it expires.
   *
   * Returns a Promise that resolves when the lock has been acquired.
   */
  async lock() {
    // Spin until we get the lock.
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (await this.tryLock()) {
        break
      }
      await sleep(this._spinDelay)
    }
  }

  /**
   * Release the lock.
   *
   * Releases the lock, regardless of who currently owns it or whether it is
   * currently locked.
   */
  async unlock() {
    const db = await this._db
    const tx = db.transaction(this._objectStoreName, 'readwrite')
    const store = tx.objectStore(this._objectStoreName)
    const unlockReq = store.put({ expiresAt: 0, owner: null }, this._name)

    return new Promise<void>((resolve, reject) => {
      unlockReq.onsuccess = () => resolve()
      unlockReq.onerror = () => reject(unlockReq.error)
    })
  }

  private _initDb(objectStoreName: string) {
    // nb. The DB version is explicitly specified as otherwise IE 11 fails to
    // run the `onupgradeneeded` handler.
    return new Promise<IDBDatabase>((resolve, reject) => {
      const openReq = indexedDB.open('idb-mutex', 1)
      openReq.onupgradeneeded = () => {
        const db = openReq.result
        db.createObjectStore(objectStoreName)
      }
      openReq.onsuccess = () => resolve(openReq.result)
      // Wrap following code in try block since it fails in Firefox when used in a private browser
      try {
        openReq.onerror = () => reject(openReq.error)
      } catch (exc) {
        captureException(exc)
      }
    })
  }

  async tryLock() {
    const db = await this._db
    const tx = db.transaction(this._objectStoreName, 'readwrite')
    const store = tx.objectStore(this._objectStoreName)

    // We use the `onsuccess` and `onerror` callbacks rather than writing a
    // generic request Promise-ifying function because of issues with
    // transactions being auto-closed when actions within a transaction span
    // Promise callbacks.
    //
    // See https://github.com/jakearchibald/idb/blob/2c601b060dc184b9241f00b91af94ae966704ee2/README.md#transaction-lifetime
    return new Promise((resolve, reject) => {
      const lockMetaReq = store.get(this._name)
      lockMetaReq.onsuccess = () => {
        const lockMeta = lockMetaReq.result
        if (!lockMeta || lockMeta.expiresAt < Date.now()) {
          const newLockMeta = {
            owner: this._id,
            expiresAt: Date.now() + this._expiry
          }
          const writeReq = store.put(newLockMeta, this._name)
          writeReq.onsuccess = () => resolve(true)
          writeReq.onerror = () => reject(writeReq.error)
        } else {
          resolve(false)
        }
      }
      lockMetaReq.onerror = () => {
        reject(lockMetaReq.error)
      }
    })
  }
}

export class MutexStub {
  tryLock() {
    return Promise.resolve(true)
  }
}

export default typeof window !== 'undefined' && window.indexedDB ? Mutex : MutexStub
