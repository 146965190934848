import { useState, useEffect } from 'react'

export default function useMediaQuery(query: string) {
  let w = typeof window !== 'undefined' ? window : undefined
  const mql = w && w.matchMedia(query)
  let [matched, matchedSet] = useState(mql?.matches)

  useEffect(() => {
    const mql = w && w.matchMedia(query)
    const handler = (e: MediaQueryListEvent) => matchedSet(e.matches)
    mql?.addListener(handler)
    return () => mql?.removeListener(handler)
  }, [query, w])

  return matched
}
