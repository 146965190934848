import React, { Component, ReactNode } from 'react'
import { captureException } from '../dev/sentry'
import RefreshPage, { ErrorState } from './RefreshPage'

type Props = {
  children: ReactNode
  fallback?: (props: ErrorState) => ReactNode
}

// We set up this global error listener
// because we want to be able to show our error page even if the error threw outside of react
let trigger: null | ((error: Error | undefined) => void) = null

if (typeof window !== 'undefined') {
  window.onerror = function(_message, _source, _lineno, _colno, error) {
    trigger && trigger(error)
    return true
  }
}

class ErrorBoundary extends Component<Props, ErrorState> {
  state: ErrorState = {}

  static getDerivedStateFromError(error: Error | null) {
    return { error }
  }

  componentDidMount() {
    trigger = error => {
      error &&
        captureException(error).then(sentryEventId => {
          sentryEventId && this.setState({ sentryEventId })
        })
      this.setState({ error })
    }
  }

  componentDidCatch(error: Error, errorInfo: object) {
    captureException(error, errorInfo).then(sentryEventId => {
      sentryEventId && this.setState({ sentryEventId })
    })
  }

  render() {
    if (this.state.error) {
      if (this.props.fallback) return this.props.fallback(this.state)
      return <RefreshPage {...this.state} />
    }
    return this.props.children
  }
}

export default ErrorBoundary
