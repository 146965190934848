export const SendbirdAuthTokensKey = 'cs:sendbird-auth-tokens'

export const ChannelUrlKey = 'cs:channel-url'
export const ChatVisibilityKey = 'cs:chat-visibility:3'

export const SelectedMessageIdKey = 'cs:selected-message-id'

export enum ChannelHandlers {
  ChannelList = 'ChannelList',
  Channel = 'Channel',
  User = 'User'
}

export const DMSlug = 'dm'
