import defaultConfig, { Config, Env } from './config.default'
import localConfig from './config.local'

const config: Config = {
  ...defaultConfig,
  ...localConfig
}

export default config
export { Env }
