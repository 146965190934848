// https://stackoverflow.com/a/13419367/434293
export const queryStringToObj = (queryString: string) => {
  let query: { [key: string]: string } = {}
  if (!queryString) return query
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}
